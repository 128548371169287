import React, { useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css"

const IndexPage = () => {
  return (
    <div className="flex flex-wrap justify-center h-screen p-4 flex-col">
      <SEO title="Home" />
      <div className="font-sans text-3xl text-center font-bold text-primary  my-5 self-center w-full">
        <h1>¡Bienvenida!</h1>
        <p className="text-lg mt-2 font-normal text-primary-900 p-2 text-left">
          Hoy te quiero traer unos cuantos recuerdos, a paertir de texto y
          algunas imágenes representativas, si tienes alguna pregunta con
          respecto al funcionamiento pregúntame, estoy aquí para lo que
          necesites. Además, cuando termines de leer todo te encontrarás con una
          linda sorpresa.
        </p>
      </div>
      <Link
        to="/app"
        className=" block outline-none my-5 w-full flex justify-center"
      >
        <button className="bg-primary rounded-lg p-1 justify-center text-white outline-none w-32 h-10 font-bold">
          Empezar
        </button>
      </Link>
    </div>
  )
}

export default IndexPage
